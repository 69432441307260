import React, { Component } from "react";
import PropTypes from "prop-types";
import SettingLogo from "../../services/settingService";
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  constructor(props) {
    super(props);
    this.settingServ = new SettingLogo();
  }
  state = { copyright: "", copyrightUrl: "" };

  componentDidMount() {
    this.settingServ.listServiceWithoutAuth(0, 100000).then(
      (result) => {
        let copyright, copyrightUrl;

        result.data.forEach((setting) => {
          if (setting.name === "copyright") {
            copyright = setting.value;
          } else if (setting.name === "copyrightUrl") {
            copyrightUrl = setting.value;
          }
          this.setState({ copyright: copyright, copyrightUrl, copyrightUrl });
        });
      },
      (error) => {
        alert("Something went wrong in setting");
      }
    );
  }
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      // Copyright © 2020 Publishing Clinic. All rights reserved
      <React.Fragment>
        <span>
          <a href={this.state.copyrightUrl} target="_blank">
            Copyright
          </a>{" "}
          {this.state.copyright}
        </span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
